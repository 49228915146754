<!-- 采购订单 -->
<template>
  <div class="zt-page-content">
    <!-- <ItemApply @shareState="shareState"></ItemApply> -->
    <!-- shareItem && shareItem.stat==2 -->
    <div style="min-width:1200px;height:100%;overflow-y:hidden;">
      <div class="zt-block flex flex-v" style="height:calc(100% - 40px);padding:20px 20px 0 20px;">
        <div class="head flex">
            <div class="select-wrap flex">
                <label>订单状态</label>
                <el-select style="width:140px;margin-right:10px;"
                    v-model="orderState" @change="order_state" 
                    :popper-append-to-body="false" placeholder="全部">
                    <el-option v-for="item in options" :key="item.id"
                    :label="item.value" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="select-wrap flex">
                <label>交货日期</label>
                <el-date-picker @change="order_time"
                    style="width:280px"
                    v-model="timeValue"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </div>
            <!-- <div class="select-wrap flex">
                <label>物流状态</label>
                <el-select value="" placeholder="请选择">
                    <el-option value="1">1</el-option>
                </el-select>
            </div> -->
            <div class="select-wrap flex-1 flex">
                <div class="flex-1"></div>
                <div style="width: 270px">
                    <el-input
                    v-model="searchValue" @change="search" 
                    placeholder="商品名称" 
                    prefix-icon="el-icon-search"/>
                </div>
            </div>
        </div>
        <div class="thead">
          <el-row>
            <el-col :span="4">商品名称</el-col>
            <el-col :span="6">商品规格</el-col>
            <el-col :span="4">交货数量</el-col>
            <el-col :span="5">工厂进货价</el-col>
            <el-col :span="5">成交价</el-col>
          </el-row>
        </div>
        <div style="overflow-y:auto;" class="table flex-1" ref="load">
          <div class="tbody">
            <div v-for="(item,idx) in list" :key="idx" class="order-block">
              <div class="order-head flex">
                <div class="flex-1">订购日期：{{item.createdAt | moment("YYYY/MM/DD HH:mm:ss")}}</div>
                <div class="flex-1">
                  <!-- <span v-if="item.buyer.store && item.buyer.store.stat==2 || item.buyer.store && item.buyer.store.stat==4">代理商：</span> -->
                  <span v-if="item.src==1">代理商：</span>
                  <span v-else>客户：</span>
                  <span>{{item.buyerName}}</span>
                </div>
                <div class="flex-1">
                  订货状态：
                  <span v-if="item.price_type!=5 && comp_type!=1">
                    <span v-if="item.isPurchase">已转采购订单</span>
                    <span v-else>未转采购订单</span>
                  </span>
                  <span v-else>--</span>
                </div>
                <div class="flex-1 ellipsis">
                    <!-- <span v-if="item.buyer.store && item.buyer.store.stat==2 || item.buyer.store && item.buyer.store.stat==4">代理商付款状态：</span> -->
                    <span v-if="item.src==1">代理商付款状态：</span>
                    <span v-else>客户付款状态：</span>
                    <span v-if="item.state<5">未付款</span>
                    <span v-if="item.state==5">已付款</span>
                    <span v-if="item.state>=6">已确认收款</span>
                </div>
                <div class="flex-1">
                    代理商发货：
                    <span v-if="item.state<=6">未发货</span>
                    <span v-if="item.state==7">已发货</span>
                    <span v-if="item.state==8">已收货</span>
                </div>
              </div>
              <div class="order-body">
                <el-row v-for="(item1,idx1) in item.entries" :key="idx1">
                  <el-col :span="4">
                    <a v-if="item1.fxItemSku && item1.fxItemSku.spu" @click="gotoDetail(item1.fxItemSku.spu)" class="ellipsis">{{item1.fxItemSku.spu.name}}</a>
                  </el-col>
                  <el-col :span="6">
                      <span v-if="item1.fxItemSku">{{item1.fxItemSku.material.name}}</span>
                      <span v-if="item1.fxItemSku">/{{item1.fxItemSku.color.name}}</span>
                      <span v-if="item1.fxItemSku && item1.fxItemSku.attrs && item1.fxItemSku.attrs.length>0">
                          <span v-for="(attr,idx1) in item1.fxItemSku.attrs" :key="idx1">
                              /{{attr.value}}
                          </span>
                      </span>
                  </el-col>
                  <el-col :span="4">{{item1.count}}</el-col>
                  <el-col :span="5">
                    <span class="price">￥{{item1.inPrice | priceFilt}}</span>
                  </el-col>
                  <el-col :span="5">
                      <span class="price">￥{{item1.price | priceFilt}}</span>
                  </el-col>
                </el-row>
              </div>
              <div class="order-foot flex">
                <!-- <div class="flex-1 ellipsis">售后：售后内容售后内容</div>
                <div class="flex-1">运费：￥128.00</div>-->
                <div class="flex-1"></div>
                <div style="text-align:right;" class="flex">
                    <div v-if="item.price_type==5 || comp_type==1">
                      <el-button v-if="item.state==1" @click="qr_order(item)" size="small" type="text">确认订单</el-button>
                    </div>
                    <div v-else>
                      <el-button v-if="item.state==1" @click="zcg_order(item,1)" size="small" type="text">确认并转采购订单</el-button>
                      <el-button v-if="item.state==1" @click="qr_order(item)" size="small" type="text">确认暂不转采购订单</el-button>
                      <el-button v-if="item.state>=2 && !item.isPurchase" @click="zcg_order(item)" size="small" type="text">转采购订单</el-button>
                    </div>
                    <el-button v-if="item.state==5" @click="view_pay(item)" size="small" type="text">确认收款</el-button>
                    <el-button v-if="item.state>=6" @click="view_pay(item)" size="small" type="text">查看收款记录</el-button>
                    <el-button @click="order_detail(item)" size="small" type="text">订单详情</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="pageShow" style="padding:15px 0;text-align:center;">
            <el-pagination background layout="prev, pager, next" 
                @current-change="currentChange"
                :current-page.sync="queryOpt.page.pageNumber" 
                :page-size="queryOpt.page.pageCount" 
                :total="total">
            </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {} from "@/service/company";
import { IMG_URL_PRE } from "@/config";
import { saleOrderList, orderConfirm, orderToPurOrder } from "@/service/sharer";
import { getPayId } from "@/service/pay";
// import ItemApply from './ItemApply.vue';

export default {
  // components: { ItemApply },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popVisible: false,
      popTitle: "",
      queryOpt: {
        where: {
            rel:{
                hasBuyerInfo:true,
                hasSkuInfo:true
            },
            isPurchase:null,
            key:null,
            createdAt:{
                start:null,
                end:null,
            }
        },
        page: {
          pageNumber: 1,
          pageCount: 15
        },
        order: [["createdAt", "desc"]],
      },
      total: 0,
      pageShow:false, // 强制刷新分页组件
      list: [],
      // 订单状态
      options: [
        { id: 0, value: "全部" },
        { id: 1, value: "未转采购订单" },
        { id: 2, value: "已转采购订单" }
      ],
      orderState:0,
      timeValue:"",
      searchValue:null,
      shareItem:null,
      comp_type:null,
    };
  },
  created() {},
  mounted() {
      this.comp_type = this.$store.state.loginUser.comp.comp_type;
      if (this.$route.query.page && this.$route.query.page>1) {
          if (this.$route.query.key) {
              this.queryOpt.where.key = this.$route.query.key;
              this.searchValue = this.queryOpt.where.key;
          }
          this.queryOpt.page.pageNumber=parseInt(this.$route.query.page);
          this.refreshItems();
      } else {
          if (this.$route.query.key) {
              this.queryOpt.where.key = this.$route.query.key;
              this.searchValue = this.queryOpt.where.key;
          }
          this.refreshItems();
      }
      this.$nextTick(()=>{
          this.pageShow = true;
      })
  },
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    gotoDetail(item) {
        console.log(item);
        if (this.comp_type==1) {
          window.open('/factory/zysp/detail/'+item.factory_product_no);
        } else {
          window.open('/sale/mall/home/factory_detail/'+item.factory_product_no);
          // if (item.price_type==1) {
          //     window.open('/sale/mall/home/design_detail/'+item.factory_product_no);
          // } else if (item.price_type==0) {
          //     window.open('/sale/mall/home/factory_detail/'+item.factory_product_no);
          // }
        }
    },
    // 子组件传过来的总代审核状态
    shareState(item) {
        this.shareItem = item;
        if (item.stat == 2) {
          // this.refreshItems();
        }
    },
    refresh() {
        this.queryOpt.page.pageNumber = 1;
        if (this.queryOpt.page.pageNumber != this.$route.query.page) {
            this.$router.push({
                query:{ page:1, key: this.searchValue}
            });
        }
        this.pageShow = false;
        this.refreshItems();
        this.$nextTick(()=>{
            this.pageShow = true;
        })
    },
    order_time(e) {
        console.log(e);
        if (e) {
          this.queryOpt.where.createdAt.start = new Date(e[0]).getTime();
          this.queryOpt.where.createdAt.end = new Date(e[1]).getTime();
        } else {
          this.queryOpt.where.createdAt.start = null;
          this.queryOpt.where.createdAt.end = null;
        }
        this.refresh();
    },
    //搜索条件改变时触发的事件
    search(e) {
        this.queryOpt.where.key = e;
        this.refresh();
    },
    order_state(event) {
      if (event == 0) {
          this.queryOpt.where.isPurchase = null;
      } else if (event == 1) {
          this.queryOpt.where.isPurchase = false;
      } else if (event == 2) {
          this.queryOpt.where.isPurchase = true;
      }
      this.refresh();
    },
    currentChange(e) {
        if (this.queryOpt.where.key) {
            this.searchValue = this.queryOpt.where.key;
            this.$router.push({
                query:{ page:e, key: this.queryOpt.where.key}
            });
        } else {
            this.$router.push({
                query:{ page:e }
            });
        }
        this.refreshItems();
    },
    refreshItems() {
        console.log("refreshItems");
        this.list = [];
        const _loading = this.$loading({
            target:this.$refs.load,
            lock: true,
            text: "拼命加载中....",
        });
        saleOrderList(this.queryOpt).then(rst => {
            this.total = rst.count;
            rst.rows.forEach((e)=>{
                let num = 0;
                e.entries.forEach((n)=>{
                    if (n.fxItemSku && n.fxItemSku.spu && n.fxItemSku.spu.price_type==5) {
                        num++;
                    }
                })
                if (num==e.entries.length) {
                    e.price_type = 5
                }
            })
            this.list = rst.rows;
            console.log(this.list);
            _loading.close();
        }).catch(err => {
            console.log(err);
            this.$message.error(err.message);
            _loading.close();
        });
    },
    order_detail(item) {
      this.$router.push("/agent/agentOrder/detail/" + item.id);
    },
    qr_order(item) {
        this.$confirm("是否确认订单？", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            closeOnClickModal: false,
            type: "warning",
        }).then(() => {
            orderConfirm(item.id).then(rst => {
                this.refreshItems();
                this.$message.success("确认订单成功");
            }).catch(err => {
                console.log(err);
                this.$message.error(err.message);
            });
        });
    },
    zcg_order(item,type) {
        this.$router.push({
            path:"/agent/agentOrder/zcgdd/"+item.id,
            query:{
                type:type,
            }
        });
        // this.$confirm("是否确定"+type+"？", "温馨提示", {
        //     confirmButtonText: "确定",
        //     cancelButtonText: "取消",
        //     closeOnClickModal: false,
        //     type: "warning",
        // }).then(() => {
        //     orderToPurOrder(item.id,{addressId:item.id,}).then(rst => {
        //         this.refreshItems();
        //         this.$message.success(type+"成功");
        //     }).catch(err => {
        //         console.log(err);
        //         this.$message.error(err.message);
        //     });
        // });
    },
    // 确认收款 ,  查看订单记录
    // qr_order(item) {
    //   getPayId('pur_order',item.id).then(rst => {
    //     console.log("getPayId", rst);
    //     this.$router.push({
    //         path:"/pay/payInfo",
    //         query:{
    //             order_code:rst.id,
    //             order_id:item.id,
    //         }
    //     });
    //   }).catch(err => {
    //     console.log(err);
    //     this.$message.error(err.message);
    //   });
      
    // },
    // 查看收款记录
    view_pay(item) {
        this.$router.push({
            path:"/pay/payeeList",
            query:{
                order_id:item.tradeOrderId,
                order_type:"fx"
            }
        });
    
    },

  }
};
</script>
<style scoped src="./style.css"></style>
<style scoped>
.select-wrap>>> .el-date-editor .el-range-separator {
    width: 10%;
}
div.tbody>>> .el-button.el-button {
  margin-left: 10px;
}
</style>
<style scoped lang="less">
.block {
  padding: 13px 32px 63px;
  color: #808080;
}
.head {
  width: 100%;
  margin-bottom: 19px;
  .tabs {
    display: flex;
    align-items: center;
    span {
      width: 2px;
      height: 50px;
      background-color: #858585;
      margin: 0 16px;
    }
    .tab {
      width: 128px;
      height: 42px;
      border-radius: 21px;
      background: #fff;
      opacity: 1;
      border-radius: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4d4d4d;
      cursor: pointer;
      font-size: 20px;
      &.active {
        color: #fff;
        background-color: #5074ee;
      }
    }
  }
  .select-wrap {
    font-size: 16px;
    align-items: center;
    margin-right: 50px;
    label {
      margin-right: 16px;
    }
  }
}
.thead {
  background: rgba(245, 245, 245, 1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 16px;
  font-weight: bold;
  .el-col {
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
}
.table {
  .tbody {
    .order-block {
      margin-top: 16px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border: 1px solid #e6e6e6;
      .order-head {
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
        background-color: #f5f5f5;
        // display: flex;
        position: relative;
        text-align: center;
        > div:last-child {
          text-align: center;
        }
        a {
          position: absolute;
          right: 40px;
        }
      }
      .order-body {
        .el-col {
          height: 60px;
          line-height: 60px;
          text-align: center;
          span.price {
            font-size: 16px;
            color: #F66F6A;
          }
        }
      }
      .order-foot {
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        border-top: 1px solid #e6e6e6;
        // display: flex;
        position: relative;
        text-align: center;
        > div:last-child {
          text-align: center;
        }
        a {
          position: absolute;
          right: 40px;
        }
      }
    }
  }
}
</style>
